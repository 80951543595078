import MP4Box from 'mp4box'
import store from '@/store'

// 判断是否是移动端
export function isMobile() {
  let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  return flag;
}

// 防抖
export function debounce(func, wait) {
  let timeout;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}

// 节流函数
export function throttle(fn, interval) {
  let lastTime = 0
  return function() {
    const context = this;
    const args = arguments;
    const nowTime = new Date().getTime()
    const remainTime = interval - (nowTime - lastTime)
    if (remainTime <= 0) {
      fn.apply(context, args)
      lastTime = nowTime
    }
  }
}

// 根据url获取obs地址
export const getMediaObsAddress = (url) => {
  if (!url) return ''
  url = url.trim()
  if (url.startsWith('http')) {
    return url
  }
  const hostname = window.location.hostname;
  if (hostname === 'test.sorai.ai') {
    return "https://digital-public-dev.obs.cn-east-3.myhuaweicloud.com" + url;
  } else if (hostname.indexOf('sorai.ai') > -1) {
    return "https://cdn.sorai.ai" + url;
  } else {
    return "https://digital-public-dev.obs.cn-east-3.myhuaweicloud.com" + url;
    // return 'https://anylang.obs.ap-southeast-3.myhuaweicloud.com';
  }
};

// 格式化日期
export const formatTimespan = (timestamp) => {
  if (!timestamp) return ''
  let date = new Date(parseInt(timestamp));
  let Year = date.getFullYear();
  let Moth =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let Day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let Hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let Minute =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  let Sechond =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  let GMT =
    Year + "-" + Moth + "-" + Day + "   " + Hour + ":" + Minute + ":" + Sechond;
  return GMT;
};

// 获取是否是free
export const isFree = () => {
  const { userInfo, accountInfo, config } = store.state
  if (!userInfo) return true
  if (accountInfo.packageName === 'Free') {
    // 获取当前毫秒数
    const nowMilliseconds = new Date().getTime()
    const m = nowMilliseconds - userInfo.createTime
    return m < config.freeDays * 24 * 60 * 60 * 1000
  }
  return false
}

// 获取视频详情
export function getVideoInfoByFile(file) {
  const mp4BoxFile = MP4Box.createFile(false)
  return new Promise((resolve, reject) => {
    mp4BoxFile.onReady = function(info) {
      console.log('info', info)
      const { videoTracks } = info
      resolve(videoTracks[0])
    }
    mp4BoxFile.onError = function (e) {
      reject(e)
    }
    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file.raw || file)
    fileReader.onload = (evt) => {
      const buffer = evt.target.result
      buffer.fileStart = 0
      mp4BoxFile.appendBuffer(buffer)
      mp4BoxFile.flush()
    }
  })
}