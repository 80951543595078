<template>
  <header>
    <div class="logo"></div>
    <ul class="nav">
      <li><span @click="$router.push('/m/index')">Home</span></li>
    </ul>
  </header>
</template>

<script>
import { throttle } from '@/utils'
export default {
  name: 'MobileInnerHeader',
  mounted() {
    this.$nextTick(() => {
      this.handleScroll()
    })
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 滚动监听(节流)
    handleScroll: throttle(function() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.scrollTopNum = scrollTop
      let header = document.querySelector('header')
      if (scrollTop > 200) {
        header.classList.add('white')
      } else {
        header.classList.remove('white')
      }
    }, 100),
  }
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 96%;
  z-index: 99;
  padding: 22px 3%;
  &.white {
    background-color: rgba(#fff, 1);
    .logo {
      background: url(@/assets/images/new_home/logo-blank.png) no-repeat 0 0;
      background-size: 100% auto;
    }
    .nav {
      span {
        color: #000;
      }
    }
  }
  .logo {
    background: url(@/assets/images/new_home/logo.png) no-repeat 0 0;
    background-size: 100% auto;
    width: 246px;
    height: 52px;
  }
  .nav {
    display: flex;
    align-items: center;
    gap: 42px;
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      cursor: pointer;
      span {
        color: #fff;
        font-size: 24px;
        padding: 5px 20px;
        line-height: 36px;
        vertical-align: middle;
        &.active {
          background: rgba(0,0,0,0.3);
          border-radius: 15px;
        }
      }
      img {
        vertical-align: middle;
        width: 40px;
      }
      &:last-child,  &:nth-child(1) {
        span {
          padding-left: 5px;
        }
      }
    }
  }
}
</style>