<template>
  <div class="right-header">
    <div v-if="userInfo" class="logined">
      <span></span><!--Credits：10-->
      <span @click="$router.push('/user/myLibrary')">My Library</span>
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          <img src="@/assets/images/user/ava.png" alt="">
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">Logout</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-else class="nologin">
      <span @click="$router.push('/login')">Login</span>
      <el-button @click="$router.push('/register')" class="custom-btn">Sign up Free</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { signOut } from '@/api'

export default {
  name: 'RightHeader',
  data() {
    return {
      
    }
  },
  computed: {
    ...mapState(['userInfo', 'token'])
  },
  methods: {
    handleCommand(command) {
      if (command === 'logout') {
        this.$http(signOut, { token: this.token }).then(() => {
          this.$store.commit('setUserInfo', '')
          this.$store.commit('setToken', '')
          this.$store.commit('SET_ACCOUNT_INFO', {})
          localStorage.clear()
          this.$router.push('/login')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nologin {
  display: flex;
  align-items: center;
  justify-content: right;
  & > span {
    display: inline-block;
    padding:5px 25px;
    cursor: pointer;
  }
}
.logined {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 36px;
  & > span {
    display: inline-block;
    padding: 5px 25px;
    word-break: keep-all;
    &:nth-child(2) {
      cursor: pointer;
    }
  }
  img {
    width: 48px;
    display: inline-block;
  }
  .el-dropdown-link {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
</style>