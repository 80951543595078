<template>
  <div class="part4">
    <div class="content footer">
      <p>CONTACT US</p>
      <img src="@/assets/images/new_home/icon.png" width="47" alt="">
      <p>You can share the characteristics of your ideal<br> idol by sending us an email.</p>
      <p>Please send me an email<br><span><a href="mailto:soraifans@gmail.com">soraifans@gmail.com</a></span></p>
      <p>more music:</p>
      <img @click="goLink('https://www.youtube.com/@SoraiAiIdol')" src="@/assets/images/new_home/youtube-icon.png" height="30" alt="">
      <img @click="goLink('https://www.tiktok.com/@.aixiameta')" style="margin-left: 40px;" src="@/assets/images/new_home/tiktok.png" width="73" height="30" alt="">
      <img @click="goLink('https://v.douyin.com/iYsA3VLw/')" style="margin-left: 40px;" src="@/assets/images/new_home/douyin.png" width="49" height="30" alt="">
      <img @click="goLink('https://twitter.com/sorai58613?s=11')" style="margin-left: 40px;" src="@/assets/images/new_home/twitter.png" height="30" alt="">
      <img @click="goLink('https://discord.gg/kKWmzeQg')" style="margin-left: 40px;" src="@/assets/images/new_home/discora.png" height="30" alt="">
      <img @click="goLink('https://whatsapp.com/channel/0029VaXnQB3BPzjT4QX3KG1j')" style="margin-left: 40px;" src="@/assets/images/new_home/wechat.png" height="30" alt="">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goLink(url) {
      window.open(url, '_blank')
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 1200px;
  margin: 0 auto;
}
.part4 {
  background: url(@/assets/images/new_home/footer-bg.png) no-repeat bottom right;
  background-size: 100% 100%;
  text-align: center;
  padding: 217px 0 0;
  height: calc(100% - 217px);
  color: #fff;
  p:nth-child(1) {
    font-size: 60px;
    color: #fff;
    line-height: 35px;
    font-size: 60px;
  }
  img:nth-child(2) {
    margin: 80px 0 36px;
  }
  p:nth-child(3) {
    opacity: 0.8;
    font-size: 16px;
    line-height: 26px;
  }
  p:nth-child(4) {
    padding-top: 36px;
    color: rgba(#fff, .8);
    font-size: 16px;
    line-height: 38px;
    span a {
      color: #fff;
      font-size: 24px;
    }
  }
  p:nth-child(5) {
    margin-top: 47px;
    color: rgba(#fff, .8);
    font-size: 16px;
    padding-bottom: 10px;
  }
  img:nth-child(6), img:nth-child(7),img:nth-child(8),img:nth-child(9) ,img:nth-child(10) ,img:nth-child(11)  {
    cursor: pointer;
  }
}
</style>