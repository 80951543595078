<template>
  <div class="part4">
    <div class="footer">
      <p>CONTACT US</p>
      <img src="@/assets/images/new_home/icon.png" width="47" alt="">
      <p>You can share the characteristics of your ideal<br> idol by sending us an email.</p>
      <p>Please send me an email<br><span><a href="mailto:soraifans@gmail.com">soraifans@gmail.com</a></span></p>
      <p>more music:</p>
      <img @click="goLink('https://www.youtube.com/@SoraiAiIdol')" src="@/assets/images/new_home/youtube-icon.png" width="32" height="36" alt="">
      <img @click="goLink('https://www.tiktok.com/@.aixiameta')" style="margin-left: 40px;" src="@/assets/images/new_home/tiktok.png" width="73" height="30" alt="">
      <img @click="goLink('https://v.douyin.com/iYsA3VLw/')" style="margin-left: 40px;" src="@/assets/images/new_home/douyin.png" width="49" height="30" alt="">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goLink(url) {
      window.open(url, '_blank')
    },
  }
}
</script>

<style lang="scss" scoped>
.part4 {
  background: url(@/assets/images/m/bg2.png) #000000 no-repeat bottom right;
  background-size: 100% auto;
  text-align: center;
  padding: 313px 0 383px;
  color: #fff;
  p:nth-child(1) {
    font-weight: bold;
    color: #fff;
    line-height: 35px;
    font-size: 46px;
  }
  img:nth-child(2) {
    margin: 80px 0 36px;
  }
  p:nth-child(3) {
    opacity: 0.8;
    font-size: 20px;
    line-height: 26px;
  }
  p:nth-child(4) {
    padding-top: 36px;
    color: rgba(#fff, .8);
    font-size: 20px;
    line-height: 38px;
    span a {
      color: #fff;
      font-size: 28px;
    }
  }
  p:nth-child(5) {
    margin-top: 68px;
    color: rgba(#fff, .8);
    font-size: 20px;
    padding-bottom: 10px;
  }
  img:nth-child(6), img:nth-child(7),img:nth-child(8) {
    cursor: pointer;
  }
}
</style>