<template>
  <div v-if="visible" class="preview">
    <i class="close" @click="$emit('update:visible', false)"></i>
    <div v-if="videoInfo.source === 'youtube'" id="ytplayer"></div>
    <video v-else :src="videoInfo.url" autoplay ref="video" loop @click.stop></video>
  </div>
</template>

<script>
export default {
  name: 'PreviewVideo',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    videoInfo: {
      type: Object,
      default: () => ({ url: '' })
    }
  },
  data() {
    return {

    }
  },
  watch: {
    visible(val) {
      if (val) {
        document.body.style.overflow = 'hidden'
        this.$nextTick(() => {
          if (this.videoInfo.source === 'youtube') {
            this.createYoutubePlayer()
          } else {
            const video = this.$refs.video
            video.addEventListener('loadedmetadata', () => {
              video.play()
            })
          }
        })
      } else {
        document.body.style.overflow = 'auto'
      }
    }
  },
  methods: {
    // 创建youtube播放器
    createYoutubePlayer() {
      this.player = new YT.Player('ytplayer', {
        videoId: this.videoInfo.url,
        playerVars: {
          playsinline: 1,
          autoplay: 1,
          controls: 0,
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.preview {
  background-color: rgba(#000, .6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  .close {
    position: absolute;
    top: 50px;
    right: 50px;
    width: 42px;
    height: 42px;
    cursor: pointer;
    background: url('@/assets/images/m/close-icon.png') no-repeat;
    background-size: 100% 100%;
  }
  video {
    width: 90%;
    position: absolute;
    left: 5%;
    top: 40%;
    transform: translateY(-50%);
    z-index: 1000;
    border-radius: 8px;
  }
}
:deep {
  iframe {
    width: 90%;
    position: absolute;
    left: 5%;
    top: 40%;
    transform: translateY(-50%);
    z-index: 1000;
    border-radius: 8px;
  }
}
</style>