<template>
  <div class="home">
    <header :class="[{white: isShowWhite}]">
      <div class="logo" @click="scrollToSection(0)"></div>
      <ul class="nav">
        <li><span :class="[{active: isShowActive(1)}]" @click="scrollToSection(1)">Video</span></li>
        <li><span :class="[{active: isShowActive(3)}]" @click="scrollToSection(3)">News</span></li>
        <li>
          <span>Artist</span><i class="arrow"></i>
          <div class="dropdown">
            <div class="dropdown-item" @click="$router.push('/fiona')">
              <img src="@/assets/images/new_home/ava-icon.png" width="30" alt=""><span>fiona</span>
            </div>
            <div class="dropdown-item" @click="$router.push('/aixia')">
              <img src="@/assets/images/new_home/aixia-icon.png" width="30" alt=""><span>aixia</span>
            </div>
            <div class="dropdown-item" @click="$router.push('/zoey')">
              <img src="@/assets/images/new_home/zoey-icon.png" width="30" alt=""><span>zoey</span>
            </div>
            <div class="dropdown-item" @click="$router.push('/artist')">
              <span class="right-arrow">more</span>
            </div>
          </div>
        </li>
        <li><span @click="$router.push('/user/credits')">Pricing</span></li>
      </ul>
      <div class="link">
        <RightHeader :class="[{white: !isShowWhite}]" />
        <!-- <i class="ytb-icon" @click="goLink('https://www.youtube.com/@SoraiAiIdol')"></i>
        <i class="tiktok-icon" @click="goLink('https://www.tiktok.com/@.aixiameta')"></i>
        <i class="douyin-icon" @click="goLink('https://v.douyin.com/iYsA3VLw/')"></i> -->
      </div>
    </header>
    <swiper :options="swiperOption" ref="myOutSwiper" @slideChange="onSlideChange">
      <swiper-slide>
        <div class="part1">
          <div class="part1_content">
            <div class="author">
              <img v-if="currentAuthorIndex === 0" class="author-img" src="@/assets/images/new_home/author/author1.png" alt="">
              <img v-if="currentAuthorIndex === 1" class="author-img" src="@/assets/images/new_home/author/author2.png" alt="">
              <img v-if="currentAuthorIndex === 2" class="author-img" src="@/assets/images/new_home/author/author3.png" alt="">
              <img class="play-icon" src="@/assets/images/new_home/play-icon.png" alt="" @click="handlePreviewVideo">
              <div class="author-ava">
                <span :class="['ava', 'ava1', { active: currentAuthorIndex === 0 }]" @click="currentAuthorIndex = 0"></span>
                <span :class="['ava', 'ava2', { active: currentAuthorIndex === 1 }]" @click="currentAuthorIndex = 1"></span>
                <span :class="['ava', 'ava3', { active: currentAuthorIndex === 2 }]" @click="currentAuthorIndex = 2"></span>
              </div>
            </div>
            <div class="create">
              <div class="create-text">
                <img src="@/assets/images/new_home/create-text.png" alt="">
                <p>Enter your song link and get your first video for free!</p>
              </div>
              <div class="create-form">
                <template v-if="videoDetail.id">
                  <img class="cover" src="@/assets/images/user/video_plhouder.png" alt="">
                  <el-progress
                    class="progress" 
                    type="circle" 
                    :percentage="videoDetail.progress"
                    :width="56"
                    :stroke-width="7"
                    define-back-color="rgba(255, 255, 255, 0.3)"
                    color="#fff"
                    :show-text="false"
                  ></el-progress>
                  <div class="btns">
                    <button @click="$router.push('/user/myLibrary')">
                      <img :src="currentSinger.avatar" alt="">
                      <span>My Library</span>
                    </button>
                    <button @click="handleReset">New video</button>
                  </div>
                </template>
                <template v-else>
                  <template v-if="currentSong.id">
                    <div class="select-song">
                      <img v-if="audioPlayState" src="@/assets/images/new_home/pause-icon2.png" @click="playOrPauseAudio">
                      <img v-else src="@/assets/images/new_home/play-icon2.png" @click="playOrPauseAudio">
                      <span>{{ currentSong.title }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <input v-model="currentSong.url" type="text" placeholder="Enter your song link" @blur="handleBlur">
                  </template>
                  <i v-if="currentSong.id" class="el-icon-error" @click="handleDelete"></i>
                  <el-button class="random-song" @click="getRandomSong">Random Song</el-button>
                  <div class="create-music">
                    <el-tooltip placement="top">
                      <span slot="content">
                        <video class="guide-video" src="https://digital-public.obs.cn-east-3.myhuaweicloud.com/tici/HowCopySongLink.mp4" autoplay loop muted></video>
                      </span>
                      <img @click="previewGuide" class="guide" src="@/assets/images/new_home/guide.png" alt="">
                    </el-tooltip>
                    <span @click="goLink('https://suno.com/')">Create song</span>
                  </div>
                  <div class="choose-singer" @click="selectAuthorVisible = true">
                    <div v-if="currentSinger.id">
                      <img :src="currentSinger.avatar" alt="">
                      <span>{{ currentSinger.name }}</span>
                    </div>
                    <span v-else>Choose singer</span>
                  </div>
                  <button @click="handleCreate">
                    <span>Create {{ userIsFree ? 'free' : '' }} video</span>
                    <i class="create-icon"></i>
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div id="part2" class="part2">
          <div class="panel">
            <div class="panel-title" style="padding-bottom: 10px;">VIDEO</div>
            <div class="panel-content">
              <div class="btns">
                <i :class="['prev-icon', { disabled: activeIndex === 0 }]"></i>
                <span>{{ activeIndex + 1 }} of {{ videoList.length }}</span>
                <i :class="['next-icon', { disabled: activeIndex === videoList.length - 1 }]"></i>
              </div>
              <swiper class="swiper" ref="mySwiper" :options="videoSwiperOption" @slideChange="onVideoSlideChange">
                <swiper-slide v-for="item, index in videoList" :key="item.etag">
                  <div class="ytb-video">
                    <iframe
                      v-if="item.videoId"
                      class="ytb-iframe"
                      :src="`https://www.youtube.com/embed/${item.videoId}?autoplay=0&autohide=1&enablejsapi=1`" 
                      frameborder="0" 
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                      allowfullscreen=""
                      style="height: 560px; width: 100%;"
                    >
                    </iframe>
                  </div>
                  <p class="title">{{ item.snippet.title }}</p>
                  <p class="time">{{ item.snippet.publishTime.substring(0, 10) }}</p>
                  <div v-if="activeIndex !== index" class="video-mask"></div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="part5">
          <div class="content">
            <img src="@/assets/images/new_home/part5_icon1.png" width="100%" alt="">
            <div class="part5_info">
              <div class="fllowers">
                <p>{{ formateNum(700000) }}</p>
                <span>followers</span>
              </div>
              <div class="songs">
                <div class="icon">
                  <img src="@/assets/images/m/home/part3_icon2.png" alt="">
                </div>
                <span>songs</span>
              </div>
              <div class="singers">
                <p>{{ formateNum(10000) }}</p>
                <span>singers</span>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div id="part3" class="part3">
          <div class="content">
            <div class="panel">
              <div class="panel-title">NEWS</div>
              <div class="panel-content">
                <div class="news">
                  <div class="news-item">
                    <img src="@/assets/images/new_home/part3-pic.png" width="707" alt="">
                    <div class="new-content">
                      <p>Sorai.ai, the Pure AI Idol Group, Wows Audiences at Beijing Film Festival</p>
                      <span>Date of issue : 2024.04.16 </span>
                      <div>Sorai.ai, a groundbreaking pure AI idol group, made a dazzling debut at the prestigious Beijing Film Festival. With their extraordinary vocalizations, crafted by AI algorithms, and personalized avatars cloned through advanced AI technology, Sorai.ai aims to revolutionize the music industry and redefine visual entertainment.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <Footer />
      </swiper-slide>
    </swiper>
    <SelectAuthor v-if="selectAuthorVisible" :info="currentSinger" @close="selectAuthorVisible = false" @pick="pickSinger" />
    <PreviewVideo :visible.sync="showVideo" :video-info="previewVideo" />
    <audio v-show="false" :src="currentSong.playUrl" ref="audio"></audio>
  </div>
</template>

<script>
import { querySongList, queryYoutubeVideo, saveVideo, synthesisVideo, queryVideoById } from '@/api'
import { mapState } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import SelectAuthor from '@/components/SelectAuthor'
import PreviewVideo from '@/components/PreviewPcVideo.vue'
import RightHeader from '@/components/RightHeader'
import Footer from '@/components/Footer'
import { throttle, isFree } from '@/utils'
import 'swiper/css/swiper.css'

export default {
  name: 'Home',
  components: {
    Swiper,
    SwiperSlide,
    SelectAuthor,
    PreviewVideo,
    RightHeader,
    Footer
  },
  data() {
    return {
      muted: true,
      swiperOption: {
        direction: 'vertical', // 竖向滚动
        autoHeight: true, // 自适应高度
        slidesPerView: 'auto', // 记得这里写 auto 不要默认写1哦
        mousewheel: true, // 支持鼠标滚动
        observer: true,
        observeParents: true,
        height: window.innerHeight
      },
      videoSwiperOption: {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 30,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.next-icon',
          prevEl: '.prev-icon',
        },
        observer: true,
        observeParents: true
      },
      slideIndex: 0,
      activeIndex: 0,
      scrollTopNum: 0,
      videoList: [],
      currentAuthorIndex: 0,
      currentSong: {
        url: ''
      },
      selectAuthorVisible: false,
      previewVideo: { url: '' },
      showVideo: false,
      songList: [],
      audioPlayState: false,
      currentSinger: {},
      videoDetail: {},
      userIsFree: isFree()
    }
  },
  computed: {
    ...mapState(['userInfo']),
    isShowWhite() {
      return this.slideIndex !== 0
    },
    isShowActive() {
      return index => index === this.slideIndex
    },
    formateNum() {
      return num => {
        const str = num.toString()
        const reg = /\B(?=(\d{3})+($|\.))/g
        return str.replace(reg, ',')
      }
    }
  },
  created() {
    this.getVideoList()
  },
  mounted() {
    this.getSongList()

    this.$nextTick(() => {
      this.audio = this.$refs.audio
      this.onAudioPlay()
    })
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer)
  },
  methods: {
    // 获取歌曲列表
    getSongList() {
      this.$http(querySongList, {
        pageNum: 1,
        pageSize: 999
      }).then(res => {
        this.songList = res.records.map(item => ({ 
          ...item, 
          playUrl: item.url.replace('https://suno.com/song/', 'https://cdn1.suno.ai/') + '.mp3'
        }))
      }).catch(err => {})
    },

    // 查询youtube视频列表
    getVideoList() {
      this.$http(queryYoutubeVideo, {
        channelId: 'UCWk2OKjmJmrOZEB2MECltOg',
        order: 'date',
        maxResults: 30
      }).then(data => {
        if (data && data.length > 0) {
          this.videoList = data.map(item => ({ ...item, videoId: item.id.videoId })).sort((a, b) => new Date(b.snippet.publishTime).getTime() - new Date(a.snippet.publishTime).getTime())
        }
      }).catch(err => {})
    },

    // 监听音频播放
    onAudioPlay() {
      this.audio.addEventListener('playing', () => {
        this.audioPlayState = true
      })
      this.audio.addEventListener('pause', () => {
        this.audioPlayState = false
      })
      this.audio.addEventListener('canplay', () => {
        this.audioPlayState = false
      })
    },

    // 滚动监听(节流)
    handleScroll: throttle(function() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.scrollTopNum = scrollTop
      let header = document.querySelector('header')
      if (scrollTop > 200) {
        header.classList.add('white')
      } else {
        header.classList.remove('white')
      }
    }, 150),

    goLink(url) {
      window.open(url, '_blank')
    },

    // 暂停YouTube视频
    pauseVideo() {
      const iframes = Array.from(document.querySelectorAll('.ytb-iframe'))
      iframes.forEach(ifra => {
        const contentWindow = ifra.contentWindow
        // stopVideo
        // playVideo
        contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
      })
    },

    // 当slide改变
    onSlideChange() {
      this.slideIndex = this.$refs.myOutSwiper.$swiper.activeIndex
    },

    // 当视频slide改变
    onVideoSlideChange() {
      this.activeIndex = this.$refs.mySwiper.$swiper.activeIndex
      // const iframes = Array.from(document.querySelectorAll('.ytb-iframe'))
      // const currentIframe = iframes[this.activeIndex]
      // currentIframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
      this.pauseVideo()
    },

    // 跳转
    scrollToSection(sectionId) {
      // const section = document.getElementById(sectionId);
      // if (section) {
      //   section.scrollIntoView({ behavior: 'smooth' });
      // }
      this.$refs.myOutSwiper.$swiper.slideTo(sectionId)
    },

    // 随机获取歌曲
    getRandomSong() {
      if (this.songList.length > 0) {
        const index = Math.floor(Math.random() * this.songList.length)
        const item = this.songList[index]
        this.currentSong = item
        console.log(this.currentSong)
      }
    },

    // 创建video并初始化数据
    handleReset() {
      if (this.timer) clearInterval(this.timer)
      this.videoDetail = {}
      this.currentSinger = {}
      this.currentSong = {
        url: ''
      }
    },

    // 播放或暂停音频
    playOrPauseAudio() {
      if (this.currentSong && this.currentSong.playUrl) {
        if (this.audio.paused) {
          this.audio.play()
        } else {
          this.audio.pause()
        }
      }
    },

    // 删除选择
    handleDelete() {
      this.audio.pause()
      this.currentSong = {
        url: ''
      }
    },

    // 输入音频改变
    handleBlur() {
      if (!this.currentSong.url) return
      if (this.currentSong.url.startsWith('https://suno.com/song/')) {
        this.currentSong = {
          id: '-1',
          url: this.currentSong.url,
          playUrl: this.currentSong.url.replace('https://suno.com/song/', 'https://cdn1.suno.ai/') + '.mp3',
          title: 'Custom'
        }
      } else {
        this.$message.error('Invalid audio link')
      }
    },

    // 选中歌手
    pickSinger(singer) {
      this.currentSinger = singer
      this.selectAuthorVisible = false
    },

    // 预览视频
    handlePreviewVideo() {
      if (this.currentAuthorIndex === 0) {
        this.previewVideo = {
          url: 'https://digital-public.obs.cn-east-3.myhuaweicloud.com/tici/fiona.mp4',
          title: 'Fiona',
          showBtn: true
        }
      }
      if (this.currentAuthorIndex === 1) {
        this.previewVideo = {
          url: 'https://digital-public.obs.cn-east-3.myhuaweicloud.com/tici/9999.mp4',
          title: 'Aixia',
          showBtn: true
        }
      }
      if (this.currentAuthorIndex === 2) {
        this.previewVideo = {
          url: 'https://digital-public.obs.cn-east-3.myhuaweicloud.com/tici/zoey.mp4',
          title: 'Zoey',
          showBtn: true
        }
      }
      this.showVideo = true
    },

    // 预览创建音乐视频
    previewGuide() {
      this.previewVideo = {
        url: 'https://digital-public.obs.cn-east-3.myhuaweicloud.com/tici/HowCopySongLink.mp4',
        title: 'Create music',
      }
      this.showVideo = true
    },

    // 创建作品
    handleCreate() {
      if (!this.currentSong.url) {
        this.$message.error('Please enter audio link')
        return
      }
      if (!this.currentSinger.id) {
        this.$message.error('Please choose singer')
        return
      }
      // if (this.userInfo && Number(this.userInfo.credits) <= 0) {
      //   this.$message.error('Insufficient credits')
      //   this.$router.push('/user/credits')
      //   return
      // }
      const movJson = {
        musicUrl: this.currentSong.url,
        modelUrl: this.currentSinger.modelTemplate,
        isSplit: true,
        isSvc: false,
        speaker: this.currentSinger.speaker
      }
      this.$http(saveVideo, {
        userId: this.userInfo.userId,
        videoName: this.currentSong.title || 'Template Name',
        movJson: JSON.stringify(movJson)
      }).then(videoId => {
        this.getVideoDetail(videoId)
        this.timer = setInterval(() => {
          this.getVideoDetail(videoId)
        }, 5000)
      }).catch(err => {})
    },

    // 根据videoId获取作品详情
    getVideoDetail(videoId) {
      this.$http(queryVideoById, { videoId }).then(data => {
        this.videoDetail = {
          ...data,
          progress: Number(data.progress)
        }
        if (Number(data.progress) === 100) {
          clearInterval(this.timer)
          this.$router.push('/user/myLibrary')
        }
      }).catch(err => {})
    },
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .swiper-slide {
    height: 100% !important;
    overflow: hidden;
  }
}
.content {
  width: 1200px;
  margin: 0 auto;
}
.panel {
  &-title {
    font-size: 60px;
    color: #000;
    font-weight: bold;
    text-align: center;
    padding-bottom: 80px;
  }
}
header {
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 96%;
  z-index: 99;
  padding: 0 3%;
  &.white {
    background-color: rgba(#fff, .9);
    .logo {
      background: url(@/assets/images/new_home/logo-blank.png) no-repeat 0 0;
      background-size: 100% auto;
    }
    .nav {
      span {
        color: #000;
      }
      .arrow {
        border-top: 5px solid #000;
      }
      li {
        &:hover {
          .arrow {
            display: inline-block;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #000 !important;
            border-top: none  !important;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .logo {
    background: url(@/assets/images/new_home/logo.png) no-repeat 0 0;
    background-size: 100% auto;
    width: 222px;
    height: 42px;
    cursor: pointer;
  }
  .nav {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 0;
    margin: 0;
    li {
      padding: 25px 0;
      margin: 0;
      list-style: none;
      cursor: pointer;
      & > span {
        color: #fff;
        font-size: 20px;
        padding: 5px 20px;
        line-height: 36px;
        vertical-align: middle;
        font-weight: normal;
        &.active {
          background: rgba(0,0,0,0.3);
          border-radius: 15px;
        }
      }
      .arrow {
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #fff;
        vertical-align: middle;
      }
      &:last-child {
        span {
          padding-right: 10px;
        }
      }
      .dropdown {
        background-color: #12121F;
        border-radius: 16px;
        border: 1px solid #393A62;
        padding: 15px 30px;
        position: absolute;
        top: 72px;
        display: none;
        &-item {
          padding: 9px 0;
          img {
            vertical-align: middle;
          }
          span {
            font-size: 18px;
            color: #fff;
            padding: 5px 10px;
            line-height: 30px;
            vertical-align: middle;
            font-weight: normal;
            &.right-arrow {
              padding-left: 3px; 
              padding-right: 25px;
              background: url(@/assets/images/new_home/right-arrow.png) no-repeat right center;
              background-size: auto 14px;
            }
          }
        }
      }
      &:hover {
        .dropdown {
          display: block;
        }
        .arrow {
          display: inline-block;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid #fff;
          border-top: none;
          vertical-align: middle;
        }
      }
    }
  }
  .link {
    .white {
      :deep {
        span {
          color: #fff !important;
        }
      }
    }
  }
}
.part1 {
  font-size: 0;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: url(@/assets/images/new_home/part1_bg.jpg) no-repeat 0 0;
  background-size: cover;
  &_content {
    width: 78%;
    min-width: 1200px;
    margin: 126px auto 0;
    position: relative;
  }
  .author {
    text-align: right;
    position: relative;
    .author-img {
      width: 818px;
    }
    .play-icon {
      position: absolute;
      top: 400px;
      right: 360px;
      cursor: pointer;
    }
    .author-ava {
      position: absolute;
      top: 94px;
      right: 0;
      .ava {
        display: block;
        width: 100px;
        height: 100px;
        margin-bottom: 32px;
        cursor: pointer;
        &.ava1 {
          background: url(@/assets/images/new_home/author/author1-ava.png) no-repeat 0 0;
          background-size: 100% 100%;
          &.active {
            background: url(@/assets/images/new_home/author/author1-ava__active.png) no-repeat 0 0;
            background-size: 100% 100%;
          }
        }
        &.ava2 {
          background: url(@/assets/images/new_home/author/author2-ava.png) no-repeat 0 0;
          background-size: 100% 100%;
          &.active {
            background: url(@/assets/images/new_home/author/author2-ava__active.png) no-repeat 0 0;
            background-size: 100% 100%;
          }
        }
        &.ava3 {
          background: url(@/assets/images/new_home/author/author3-ava.png) no-repeat 0 0;
          background-size: 100% 100%;
          &.active {
            background: url(@/assets/images/new_home/author/author3-ava__active.png) no-repeat 0 0;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
  .create {
    position: absolute;
    left: 0;
    top: 50px;
    &-text {
      img {
        width: 735px;
      }
      p {
        padding-top: 28px;
        color: rgba(255,255,255,0.8);
        font-size: 26px;
        line-height: 32px;
      }
    }
    &-form {
      margin-top: 66px;
      width: 480px;
      padding: 60px 52px;
      background: rgba(255,255,255,0.08);
      border-radius: 28px;
      border: 1px solid rgba(255,255,255,0.16);
      position: relative;
      input {
        width: calc(100% - 170px);
        background: rgba(0,0,0,0.4);
        border-radius: 12px;
        border: 1px solid rgba(255,255,255,0.12);
        height: 66px;
        font-size: 20px;
        padding: 0 150px 0 20px;
        color: #fff;
        &:focus {
          outline: none;
          border: 1px solid rgba(255,255,255,0.22);
        }
        &::placeholder {
          color: rgba(255,255,255,0.2);
        }
      }
      .select-song {
        background-color: rgba(0,0,0,0.4);
        width: calc(100% - 20px);
        border-radius: 12px;
        border: 1px solid rgba(255,255,255,0.12);
        height: 66px;
        font-size: 20px;
        padding-left: 20px;
        line-height: 66px;
        color: rgba(255,255,255,1);
        img {
          width: 28px;
          height: 28px;
          vertical-align: middle;
          cursor: pointer;
          position: relative;
          top: -1px;
        }
        span {
          display: inline-block;
          width: 258px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;
          padding-left: 6px;
        }
      }
      .choose-singer {
        background: url(@/assets/images/new_home/select-arrow.png) no-repeat rgba(0,0,0,0.4) 440px center;
        background-size: 24px 24px;
        margin-top: 34px;
        width: calc(100% - 20px);
        border-radius: 12px;
        border: 1px solid rgba(255,255,255,0.12);
        height: 66px;
        font-size: 20px;
        padding-left: 20px;
        line-height: 66px;
        cursor: pointer;
        & > span {
          color: rgba(255,255,255,0.2);
        }
        & > div {
          img {
            width: 28px;
            height: 28px;
            vertical-align: middle;
            border-radius: 50%;
            object-fit: cover;
          }
          span {
            vertical-align: middle;
            font-size: 18px;
            padding-left: 10px;
            color: #fff;
          }
        }
      }
      & > button {
        background: linear-gradient( 110deg, #9B77FF 0%, #3B3FF5 100%);
        border-radius: 12px;
        height: 62px;
        margin-top: 48px;
        border: none;
        width: 100%;
        font-weight: bold;
        font-size: 24px;
        color: #fff;
        cursor: pointer;
        .create-icon {
          display: inline-block;
          vertical-align: middle;
          width: 32px;
          height: 32px;
          background: url(@/assets/images/new_home/create-icon.png) no-repeat 0 0;
          background-size: 100% 100%;
          margin-right: 10px;
        }
      }
      .random-song {
        position: absolute;
        width: auto;
        height: 40px;
        font-size: 14px;
        font-weight: normal;
        top: 26px;
        right: 65px;
        border-radius: 6px;
      }
      .el-icon-error {
        position: absolute;
        top: 84px;
        right: 200px;
        color: rgba(255,255,255,0.5);
        font-size: 20px;
        cursor: pointer;
      }
      .create-music {
        text-align: right;
        margin-top: 14px;
        .guide {
          width: 20px;
          height: 20px;
          cursor: pointer;
          vertical-align: middle;
        }
        span {
          font-size: 18px;
          color: rgba(255,255,255,0.5);
          vertical-align: middle;
          padding-left: 6px;
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .progress {
        position: absolute;
        top: 164px;
        left: 260px;
      }
      .cover {
        width: 100%;
        border-radius: 12px;
      }
      .btns {
        display: flex;
        margin-top: 30px;
        gap: 40px;
        button {
          flex: 1;
          background: linear-gradient( 110deg, #9B77FF 0%, #3B3FF5 100%);
          border-radius: 12px;
          border-radius: 12px;
          height: 56px;
          border: none;
          font-weight: bold;
          font-size: 18px;
          color: #fff;
          cursor: pointer;
          span {
            vertical-align: middle;
            padding-left: 6px;
          }
          img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
.part2 {
  padding: 100px 0 0;
  .swiper-slide {
    width: 943px;
    min-height: 560px;
    position: relative;
    .ytb-video {
      border-radius: 6px;
      overflow: hidden;
      height: 560px;
    }
    .title {
      margin-top: 10px;
      font-size: 18px;
      color: #000;
    }
    .time {
      font-size: 16px;
      padding-top: 10px;
      color: rgba(0,0,0,0.5);
    }
    .video-mask {
      width: 943px;
      height: 560px;
      background-color: rgba(#fff, .6);
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      z-index: 9;
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
    gap: 27px;
    .prev-icon {
      display: inline-block;
      width: 42px;
      height: 42px;
      background: url(@/assets/images/new_home/prev-icon.png) no-repeat 0 0;
      background-size: 100% 100%;
      cursor: pointer;
    }
    span {
      color: rgba(0,0,0,0.5);
      font-size: 16px;
    }
    .next-icon {
      display: inline-block;
      width: 42px;
      height: 42px;
      background: url(@/assets/images/new_home/next-icon.png) no-repeat 0 0;
      background-size: 100% 100%;
      cursor: pointer;
    }
    i.disabled {
      opacity: .4;
    }
  }
}
.part3 {
  background-color: #F8F8F8;
  padding: 162px 0 0;
  height: calc(100% - 162px);
  .news {
    .news-item {
      display: flex;
      align-items: center;
      gap: 50px;
      img {
        width: 507px;
      }
    }
    .new-content {
      flex: 1;
      p {
        font-size: 28px;
        color: #000;
        line-height: 36px;
        font-weight: 600;
      }
      span {
        display: block;
        font-size: 18px;
        color: rgba(0,0,0,0.5);
        padding: 12px 0 24px;
      }
      & > div {
        border-top: 1px solid #DADADA;
        padding-top: 24px;
        font-size: 18px;
        color: rgba(0,0,0,0.8);
        line-height: 28px;
      }
    }
  }
}
.part5 {
  background: url(@/assets/images/new_home/part5_bg.jpg) no-repeat 0 0;
  background-size: 100% 100%;
  padding: 288px 0 433px;
  height: calc(100% - 288px - 433px);
  text-align: center;
  .content > img {
    width: 271px;
  }
  &_info {
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div {
      flex: 1;
    }
    .fllowers, .songs, .singers {
      p {
        font-size: 60px;
        color: #fff;
        line-height: 92px;
        font-weight: bold;
      }
      .icon {
        height: 62px;
        padding-top: 30px;
        img {
          width: 73px;
        }
      }
      span {
        font-size: 32px;
        color: rgba(255,255,255,0.6);
        line-height: 49px;
      }
    }
  }
}
.guide-video {
  width: 640px;
}
</style>