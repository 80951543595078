// const BASE_URL = process.env.BASE_URL

export default function getConfig() {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', location.origin + location.pathname + `config.json`, false)
    xhr.send()
    const config = JSON.parse(xhr.responseText)
    resolve(config)
  })
}