import { render, staticRenderFns } from "./Ruby.vue?vue&type=template&id=4b99cdf5&scoped=true"
import script from "./Ruby.vue?vue&type=script&lang=js"
export * from "./Ruby.vue?vue&type=script&lang=js"
import style0 from "./Ruby.vue?vue&type=style&index=0&id=4b99cdf5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b99cdf5",
  null
  
)

export default component.exports