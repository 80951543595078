<template>
  <div class="select">
    <div class="select-content">
      <div class="content__left">
        <span v-for="category, index in singerCategory" :key="index" :class="currentCategory === category ? 'active' : ''" @click="currentCategory = category">{{ category }}</span>
      </div>
      <div class="content__right" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.5)">
        <div v-if="!showUpload" class="author-list">
          <div v-if="currentCategory === 'My'" class="author__item" style="cursor: pointer;" @click="showUpload = true">
            <div class="author__item-img">
              <div class="upload-author">
                <img src="@/assets/images/new_home/camera-icon.png" alt="">
                <p>Upload My Avatar</p>
                <small>1G Max file size.</small>
              </div>
            </div>
          </div>
          <div v-for="singer in singerList" :key="singer.id" :class="['author__item', { active: singer.id === currentSinger.id }]">
            <div class="author__item-img">
              <img :src="singer.avatar | mediaPrefix" alt="">
              <div class="hover-box">
                <!-- <span><img src="@/assets/images/new_home/search-icon.png" alt="">SIMILAR</span> -->
                <span @click="album(singer)"><img src="@/assets/images/new_home/album-icon.png" alt="">ALBUM</span>
                <span @click="pickSinger({...singer, currentCategory})"><img src="@/assets/images/new_home/pick-icon.png" alt="">PICK</span>
                <img v-if="currentCategory === 'My'" src="@/assets/images/new_home/del-icon2.png" alt="" @click="delAnthor(singer)">
              </div>
            </div>
            <span>{{ singer.name }}</span>
          </div>
        </div>
        <div v-else class="upload-form">
          <h4>Upload your video</h4>
          <p>Make music videos with your Avatar</p>
          <div class="show-upload">
            <div v-if="uploadVideo.video" class="upload-complete">
              <video :src="getMediaObsAddress(uploadVideo.video)" controls></video>
              <img src="@/assets/images/new_home/del-icon.png" alt="" @click="clearFiles">
            </div>
            <template v-else>
              <el-upload
                v-if="uploadProgress === 0"
                action=""
                :drag="true"
                accept="video/mp4,video/quicktime"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="handleUpload"
              >
                <div class="upload-content">
                  <img src="@/assets/images/new_home/camera-icon.png" alt="">
                  <p>Drag & drop your files here or <span>choose files</span></p>
                  <small>1G Max file size.</small>
                </div>
              </el-upload>
              <div v-else class="upload-progress">
                <el-progress
                  class="progress" 
                  type="circle" 
                  :percentage="uploadProgress"
                  :width="56"
                  :stroke-width="7"
                  define-back-color="rgba(255, 255, 255, 0.3)"
                  color="#fff"
                ></el-progress>
              </div>
            </template>
          </div>
          <h4>Name</h4>
          <input v-model="videoName" placeholder="Enter your name" class="video-name" maxlength="100" />
          <button class="generate-btn" :disabled="!(uploadVideo.video && videoName)" @click="generateNewSinger">Generate New Singer</button>
          <img class="back" src="@/assets/images/new_home/back.png" alt="" @click="back">
        </div>
        <!-- <el-empty v-else description="No data" :image-size="80"></el-empty> -->
      </div>
    </div>
    <img class="close-icon" src="@/assets/images/new_home/close-icon.png" alt="" @click="$emit('close')">
    <PreviewVideo :visible.sync="showVideo" :video-info="previewVideo" />
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { querySingerList, querySingerCategory, queryMyTemplateList, saveOrUpdateMyTemplate, delMyTemplate } from '@/api'
import PreviewVideo from '@/components/PreviewPcVideo.vue'
import  { getVideoInfoByFile, getMediaObsAddress } from '@/utils'
export default {
  name: 'SelectAuthor',
  components: {
    PreviewVideo
  },
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      singerList: [],
      singerCategory: [],
      currentSinger: {},
      currentCategory: '',
      showVideo: false,
      previewVideo: {},
      loading: false,
      videoName: '',
      showUpload: false,
      uploadProgress: 0,
      uploadVideo: {},
    }
  },
  computed: { 
    ...mapState(['userInfo'])
  },
  watch: {
    currentCategory(val) {
      if (val === 'My') {
        this.getMySingerList()
      } else {
        this.showUpload = false
        this.previewVideo = {}
        this.videoName = ''
        this.uploadProgress = 0
        this.getSingerList()
      }
    }
  },
  created() {
    this.getSingerCategory()
    if (this.info.id) {
      this.currentSinger = this.info
    }
  },
  methods: {
    getMediaObsAddress,
    // 获取歌手分类
    getSingerCategory() {
      this.$http(querySingerCategory, {}).then(data => {
        if (this.userInfo) {
          data.push('My')
        }
        this.singerCategory = data
        if (this.info.id) { 
          this.currentCategory = this.info.currentCategory
        } else {
          this.currentCategory = data[0]
        }
        this.getSingerList()
      }).catch(err => {})
    },

    // 获取歌手列表
    getSingerList() {
      this.loading = true
      this.singerList = []
      this.$http(querySingerList, {
        label: this.currentCategory,
        pageNum: 1,
        pageSize: 999,
      }).then(res => {
        this.singerList = res.records
      }).catch(err => {}).finally(() => {
        this.loading = false
      })
    },

    // 获取我的歌手列表
    getMySingerList() {
      this.loading = true
      this.singerList = []
      this.$http(queryMyTemplateList, {
        pageNum: 1,
        pageSize: 999,
      }).then(res => {
        this.singerList = res.records.map(item => ({ ...item, avatar: item.cover, single: item.video, modelTemplate: item.video }))
      }).catch(err => {}).finally(() => {
        this.loading = false
      })
    },

    // 选中主播
    pickSinger(singer) {
      this.currentSinger = singer
      this.$emit('pick', this.currentSinger)
    },

    // 视频预览
    album(singer) {
      this.previewVideo = {
        url: singer.single,
        title: singer.name
      }
      this.showVideo = true
    },

    // 上传视频
    async handleUpload(file) {
      if (file.size > 1024 * 1024 * 1024) {
        this.$message.error('The file size exceeds 1G')
        return
      }
      const videoInfo = await getVideoInfoByFile(file)
      const { video, duration, timescale } = videoInfo
      const mode = video.width < video.height ? 1 : 0
      const du = parseInt(duration / timescale)
      if (du > 5 * 60) {
        this.$message.error('The duration of the video you uploaded is cannot be longer than 5 minutes . Please upload it again!')
        return
      }
      if ((mode === 0 && video.height < 720) || (mode === 1 && video.width < 720)) {
        this.$message.error('The video resolution you uploaded is lower than 720p, please upload again!')
        return
      }

      this.uploadVideo = {}
      const formData = new FormData()
      formData.set('file', file.raw)
      axios.post(`/video-api/upload/video`, formData, {
        withCredentials: false,
        headers: { 
          'Content-Type': 'multipart/form-data',
          'token': localStorage.getItem('token')
        },
        timeout: 24 * 3600 * 1000,
        onUploadProgress: (event) => {
          const percentage = Math.floor((event.loaded * 100) / event.total)
          this.uploadProgress = percentage
        }
      }).then((res) => {
        if (res.data.success) {
          this.uploadVideo = res.data.data
          console.log('上传成功', res.data)
        } else {
          this.$message.error(res.data.msg)
        }
      }).finally(() => {
        this.uploadProgress = 0
      })
    },

    // 清除视频
    clearFiles() {
      this.uploadVideo = {}
    },

    // 创建歌手
    generateNewSinger () {
      if (!this.videoName) {
        this.$message.error('Please enter your name')
        return
      }
      if (!this.uploadVideo.video) {
        this.$message.error('Please upload video')
        return
      }
      this.$http(saveOrUpdateMyTemplate, {
        name: this.videoName,
        ...this.uploadVideo
      }).then(res => {
        this.$message.success('Generate success')
        this.showUpload = false
        this.uploadVideo = {}
        this.videoName = ''
        this.getMySingerList()
      }).catch(err => {}).finally(() => {
        
      })
    },

    // 删除自己创建的歌手
    delAnthor(singer) {
      this.$confirm('Are you sure you want to delete this singer?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        this.$http(delMyTemplate, {
          id: singer.id
        }).then(res => {
          this.$message.success('Delete success')
          this.getMySingerList()
        }).catch(err => {})
      }).catch(() => {})
    },

    // 返回列表
    back() {
      this.showUpload = false
      this.videoName = ''
      this.uploadProgress = 0
      this.uploadVideo = {}
    }
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .el-upload-dragger {
    width: 480px;
    height: 270px;
    background-color: transparent;
    border: none;
  }
  .el-progress__text {
    color: #fff !important;
    font-size: 12px !important;
  }
}
.select {
  background-color: rgba(#000, .6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  &-content {
    background: #12121F;
    box-shadow: 0px 8px 32px 0px rgba(3,3,3,0.32);
    border-radius: 16px;
    border: 1px solid #393A62;
    height: 720px;
    width: 1042px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    overflow: hidden;
  }
  .close-icon {
    position: absolute;
    left: calc(50% + 550px);
    top: 5%;
    cursor: pointer;
  }
  .content__left {
    width: 168px;
    padding-top: 40px;
    span {
      display: inline-block;
      width: 100%;
      height: 48px;
      line-height: 48px;
      text-align: center;
      color: rgba(255,255,255,0.4);
      font-size: 16px;
      cursor: pointer;
      &.active {
        background-color: #272736;
        color: #fff;
      }
    }
  }
  .content__right {
    flex: 1;
    background-color: #272736;
    height: calc(100% - 52px);
    padding: 26px 0;
    .author-list {
      height: 100%;
      padding: 0 26px;
      overflow: auto;
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
      gap: 18px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: #272736;
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: rgba(255,255,255,0.2);
        border-radius: 2px;
      }
    }
    
    .author__item {
      text-align: center;
      .author__item-img {
        border: 1px solid transparent;
        font-size: 0;
        border-radius: 7px;
        background-origin: border-box;
        padding: 3px;
        position: relative;
        overflow: hidden;
        & > img {
          width: 113px;
          height: 135px;
          object-fit: cover;
          border-radius: 7px;
        }
        .hover-box {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(#000, .4);
          padding-top: 45px;
          display: none;
          span {
            background: #242730;
            border-radius: 4px;
            border: 1px solid #393A62;
            margin-bottom: 12px;
            color: #fff;
            width: 88px;
            font-size: 12px;
            display: inline-block;
            cursor: pointer;
            img {
              width: 12px;
              vertical-align: middle;
              margin-right: 3px;
            }
            &:last-child {
              background: linear-gradient( 120deg, #9B77FF 0%, #3B3FF5 100%);
              border: none;
              img {
                width: 13px;
              }
            }
          }
          & > img {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
          }
        }
        &:hover {
          .hover-box {
            display: block;
          }
        }
      }
      span {
        color: #fff;
        font-size: 14px;
        line-height: 24px;
      }
      &.active {
        .author__item-img {
          border-color: #3B3FF5;
        }
      }
      .upload-author {
        width: 109px;
        height: 101px;
        background-color: #131421;
        border-radius: 4px;
        border: 2px dashed rgba(255,255,255,0.16);
        text-align: center;
        padding-top: 30px;
        img {
          width: 32px;
          height: 32px;
        }
        p {
          color: rgba(255,255,255,0.8);
          font-size: 12px;
          line-height: 20px;
        }
        small {
          color: rgba(255,255,255,0.3);
          font-size: 10px;
        }
      }
    }
    .upload-form {
      width: 480px;
      margin: 0 auto;
      h4 {
        font-size: 24px;
        color: #fff;
        line-height: 40px;
        padding: 10px 0;
      }
      & > p {
        font-size: 16px;
        color: rgba(255,255,255,0.6);
      }
      .show-upload {
        margin-top: 20px;
        background-color: #131421;
        border-radius: 4px;
        border: 2px dashed rgba(#fff, 0.16);
        border-radius: 4px;
        text-align: center;
        height: 270px;
        width: 480px;
        margin-bottom: 20px;
        .upload-content {
          padding-top: 78px;
          img {
            width: 50px;
            height: 50px;
          }
          p {
            color: rgba(255,255,255,0.8);
            font-size: 18px;
            line-height: 20px;
            margin: 5px 0 10px;
            span {
              color: #0173F5;
            }
          }
          small {
            color: rgba(255,255,255,0.3);
            font-size: 14px;
          }
        }
        .upload-complete {
          height: 100%;
          position: relative;
          & > video {
            height: 100%;
          }
          & > img {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 26px;
            height: 26px;
            cursor: pointer;
          }
        }
      }
      .video-name {
        width: calc(480px - 40px);
        background-color: #131421;
        border-radius: 8px;
        font-size: 14px;
        color: rgba(255,255,255,0.3);
        border: none;
        height: 52px;
        outline: none;
        padding: 0 20px;
      }
      .generate-btn {
        background: linear-gradient( 110deg, #9B77FF 0%, #3B3FF5 100%);
        border-radius: 8px;
        height: 48px;
        border: none;
        font-size: 16px;
        color: #fff;
        width: 480px;
        margin-top: 32px;
        cursor: pointer;
        &[disabled=disabled] {
          opacity: .7;
        }
      }
      .upload-progress {
        position: relative;
        height: 260px;
        .progress {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .back {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 40px;
        left: 242px;
        opacity: .6;
        cursor: pointer;
      }
    }
    
  }
}
</style>