<template>
  <div class="person">
    <InnerHeader :white="isShowWhite" />
    <swiper :options="swiperOption" ref="myOutSwiper" @slideChange="onSlideChange">
      <swiper-slide>
        <div class="part1">
          <video src="https://digital-public.obs.cn-east-3.myhuaweicloud.com/tici/charlotte.mp4" autoplay loop :muted="muted"></video>
          <div class="video-mask"></div>
          <div class="layer1">
            <img src="@/assets/images/charlotte/part1_icon.png" width="50%" alt=""><br>
            <img src="@/assets/images/aixia/part1_icon2.png" width="28%" style="margin-top: 67px; cursor: pointer;" @click="goLink('https://www.youtube.com/watch?v=LLRUErgowR4')">
          </div>
          <div class="layer2">
            <img v-if="muted" @click="muted = !muted" src="@/assets/images/new_home/audio-close.png" width="48" alt="">
            <img v-else @click="muted = !muted" src="@/assets/images/new_home/audio-open.png" width="48" alt="">
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="part2">
          <img src="@/assets/images/charlotte/part2_icon.png" width="25%" alt="">
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="part3">
          <div class="content" style="width: 920px;">
            <div class="panel">
              <div class="panel-title">SINGLES</div>
              <div class="panel-content">
                <div class="singles">
                  <div v-for="item, index in audioList" :key="index" class="singles-item" @click="playAudio(item)" style="cursor: pointer;">
                    <div class="single-left">
                      <img :class="[{ rotate: item.isPlay }]" src="@/assets/images/person/part3_icon.png" width="90" alt="">
                      <div class="single-info">
                        <p>{{ item.name }} <span> | {{ item.languge }}</span></p>
                        <small class="duration">{{ item.duration }}</small>
                      </div>
                    </div>
                    <img v-if="!item.isPlay" src="@/assets/images/person/audio_play.png" style="cursor: pointer;" width="46">
                    <img v-else src="@/assets/images/person/audio_pause.png" style="cursor: pointer;" width="46" alt="" @click="pauseAudio(item)">
                  </div>
                </div>
                <audio v-show="false" ref="audio" :src="currentAudio.url"></audio>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <Footer />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import InnerHeader from '@/components/InnerHeader'
import Footer from '@/components/Footer'
export default {
  name: 'Person',
  components: {
    Swiper,
    SwiperSlide,
    InnerHeader,
    Footer
  },
  data() {
    return {
      muted: true,
      swiperOption: {
        direction: 'vertical', // 竖向滚动
        autoHeight: true, // 自适应高度
        slidesPerView: 'auto', // 记得这里写 auto 不要默认写1哦
        mousewheel: true, // 支持鼠标滚动
        height: window.innerHeight
      },
      audioList: [
        {
          url: 'https://digital-public.obs.cn-east-3.myhuaweicloud.com/100771/08e65b09574e1000.wav',
          link: 'https://www.youtube.com/watch?v=LLRUErgowR4',
          name: '【Family Ties】Original Song from AI Singer Charlotte',
          languge: 'English',
          duration: '01:02',
          isPlay: false
        }
      ],
      currentAudio: {},
      slideIndex: 0,
    }
  },
  computed: {
    isShowWhite() {
      return this.slideIndex !== 0
    },
  },
  mounted() {
    // window.addEventListener('scroll', this.handleScroll)
    this.$nextTick(() => {
      // this.handleScroll()
      this.handleAudio()
    })
  },
  methods: {
    // 监听音频播放
    handleAudio() {
      const audio = this.$refs.audio
      audio.addEventListener('playing', () => {
        if (this.currentAudio.isPlay) {
          this.currentAudio.isPlay = true
        }
      })
      audio.addEventListener('pause', () => {
        if (this.currentAudio.isPlay) {
          this.currentAudio.isPlay = false
        }
      })
      audio.addEventListener('ended', () => {
        if (this.currentAudio.isPlay) {
          this.currentAudio.isPlay = false
        }
      })
    },

    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      let header = document.querySelector('header')
      if (scrollTop > 200) {
        header.classList.add('white')
      } else {
        header.classList.remove('white')
      }
    },

    // 播放音频
    playAudio(item) {
      window.open(item.link, '_blank')
      return
      this.audioList.forEach(audio => {
        if (audio.isPlay) {
          audio.isPlay = false
        }
        if (audio === item) {
          audio.isPlay = true
        }
      })
      
      this.currentAudio = item
      this.$nextTick(() => {
        this.$refs.audio.play()
      })
    },

    // 暂停音频
    pauseAudio(item) {
      this.currentAudio.isPlay = false
      this.$nextTick(() => {
        this.$refs.audio.pause()
      })
    },

    goLink(url) {
      window.open(url, '_blank')
    },

    // 当slide改变
    onSlideChange() {
      this.slideIndex = this.$refs.myOutSwiper.$swiper.activeIndex
    },
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .swiper-slide {
    height: 100% !important;
    overflow: hidden;
  }
}
.content {
  width: 1200px;
  margin: 0 auto;
}
.panel {
  &-title {
    font-size: 60px;
    color: #000;
    font-weight: bold;
    text-align: center;
    padding-bottom: 80px;
  }
}
.part1 {
  font-size: 0;
  position: relative;
  overflow: hidden;
  height: 100vh;
  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  .video-mask {
    width: 100%;
    height: 100vh;
    background-color: rgba(#000, .2);
    position: absolute;
    top: 0;
    left: 0;
  }
  .layer1 {
    position: absolute;
    top: 35vh;
    left: 62px;
  }
  .layer2 {
    position: absolute;
    bottom: 62px;
    right: 62px;
    img {
      cursor: pointer;
    }
  }
}
.part2 {
  background: url(@/assets/images/charlotte/part2_bg.jpg) no-repeat 0 0;
  height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
  position: relative;
  img {
    position: absolute;
    left: 4%;
  }
}
.part3 {
  background-color: #F8F8F8;
  padding: 105px 0 0;
  height: calc(100% - 85px);
  .singles {
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0,0,0,0.5);
      padding-bottom: 20px;
      margin-bottom: 20px;
      &:last-child {
        border-bottom: none;
      }
    }
    .single-left {
      display: flex;
      align-items: center;
      gap: 34px;
      .rotate {
        transition-property: transform;
        animation-name: rotate;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
      .single-info {
        font-size: 18px;
        color: #000;
        p {
          line-height: 36px;
        }
        span {
          color: rgba(#000, .3);
        }
        small {
          color: rgba(#000, .3);
        }
      }
    }
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
</style>