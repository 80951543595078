<template>
  <div v-if="visible" class="preview">
    <div class="preview-content">
      <div v-if="videoInfo.source === 'youtube'" id="ytplayer"></div>
      <video v-else :src="videoInfo.url | mediaPrefix" autoplay ref="video" loop controls @click.stop></video>
      <div class="info">
        <span v-if="videoInfo.title">{{ videoInfo.title }}</span>
        <button v-if="videoInfo.showBtn" @click="$emit('update:visible', false)">
          <span>Create Video</span>
          <i class="create-icon"></i>
        </button>
      </div>
    </div>
    <i class="close" @click="$emit('update:visible', false)"></i>
  </div>
</template>

<script>
export default {
  name: 'PreviewVideo',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    videoInfo: {
      type: Object,
      default: () => ({ url: '' })
    }
  },
  data() {
    return {

    }
  },
  watch: {
    visible(val) {
      if (val) {
        document.body.style.overflow = 'hidden'
        this.$nextTick(() => {
          if (this.videoInfo.source === 'youtube') {
            this.createYoutubePlayer()
          } else {
            const video = this.$refs.video
            video.addEventListener('loadedmetadata', () => {
              video.play()
            })
          }
        })
      } else {
        document.body.style.overflow = 'auto'
      }
    }
  },
  methods: {
    // 创建youtube播放器
    createYoutubePlayer() {
      this.player = new YT.Player('ytplayer', {
        videoId: this.videoInfo.url,
        playerVars: {
          playsinline: 1,
          autoplay: 1,
          controls: 0,
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.preview {
  background-color: rgba(#000, .6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  .close {
    position: absolute;
    left: calc(50% + 550px);
    top: 15%;
    cursor: pointer;
    width: 106px;
    height: 106px;
    background: url('@/assets/images/new_home/close-icon.png') no-repeat;
    background-size: 100% 100%;
  }
  &-content {
    padding: 28px;
    background: #12121F;
    box-shadow: 0px 8px 32px 0px rgba(3,3,3,0.32);
    border-radius: 16px;
    border: 1px solid #393A62;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  video {
    max-width: 1000px;
    max-height: 80vh;
    border-radius: 8px;
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    span {
      font-size: 16px;
      color: #fff;
    }
    button {
      background: linear-gradient( 110deg, #9B77FF 0%, #3B3FF5 100%);
      border-radius: 4px;
      height: 40px;
      padding: 0 20px;
      border: none;
      font-size: 16px;
      color: #fff;
      .create-icon {
        display: inline-block;
        vertical-align: middle;
        margin-left: 3px;
        width: 14px;
        height: 12px;
        background: url(@/assets/images/new_home/create-icon.png) no-repeat 0 0;
        background-size: 100% 100%;
        margin-right: 10px;
      }
    }
  }
}
:deep {
  iframe {
    width: 90%;
    position: absolute;
    left: 5%;
    top: 40%;
    transform: translateY(-50%);
    z-index: 1000;
    border-radius: 8px;
  }
}
</style>