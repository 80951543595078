/**
 * 封装 request 请求
 * 支持 post、get、put、delete，支持路径参数
 * @return {Promise}
 */
import axios from "axios";
import message from "@/utils/message.js";
import router from "@/router";
// import store from "@/store";
import getConfig from '@/utils/getConfig'
axios.defaults.timeout = 3600 * 100;
// axios.defaults.withCredentials = true; // 允许携带cookie



if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = '';
} else {
  getConfig().then((configJson) => {
    axios.defaults.baseURL = configJson.baseURL || 'https://anylang.ai';
  });
}

axios.interceptors.request.use((config) => {
  config.headers['token'] = localStorage.getItem('token')
  return config;
});
axios.interceptors.response.use(
  (response) => {
    if (response.data.code && response.data.code === 503) {
      const account = window.localStorage.getItem('account')
      localStorage.clear();
      window.localStorage.setItem('account', account)
      // store.replaceState(Object.assign(store.state, { user: "", token: "" }));
      message.error(response.data.msg);
      setTimeout(() => {
        window.location.href = "/login";
      }, 500);
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default (path, params = {}, header, options={}) => {
  const headers = {
    token: localStorage.getItem("token"),
    'Accept-Language': "en-US", // zh-CN
    ...header,
  };
  const [url, method = "get"] = path.split(/\s+/);
  const type = method.toLowerCase();
  return new Promise((resolve, reject) => {
    const param = { method: type, url, headers };
    if (type === "get") {
      Object.assign(param, { params });
    } else {
      Object.assign(param, { data: params });
    }
    axios(param)
      .then(({ data }) => {

        if (data.code == 200 || (data.code == 0 && data.success)) {
          resolve(options?.originalResponse ? data : data.data);
        } else if (data.code === 500004 || data.code === 500008) {
          // 500004合成时长不足   500008不能上传形象数量
          message.error('Customize more avatar，please contact our sales');
          router.push('/user/credits')
          reject(data);
        } else if (data.code == 0 && !data.success) {
          reject(data);
        } else {
          if (data.code == 310008 || data.code == 310009 || data.code == 310010) {
            // 违规提示页面自己处理
            reject(data);
          } else if (data.code === 1001 || data.code === 1002) {
            // 重新登录
            localStorage.clear()
            message.error(data.msg);
            router.push('/login')
          } else {
            message.error(data.msg || data.message);
            reject(data);
          }
        }
      })
      .catch(() => {
        message.error("Request timeout.");
        reject("Request timeout.");
      });
  });
};
