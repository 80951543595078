import Vue from 'vue'
import { isMobile } from '@/utils/index.js'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Person from '../views/Person.vue'
import Aixia from '../views/Aixia.vue'
import Ruby from '../views/Ruby.vue'
import Aisha from '../views/Aisha.vue'
import Zoey from '@/views/Zoey.vue'
import Elise from '@/views/Elise.vue'
import Grace from '@/views/Grace.vue'
import Artist from '@/views/Artist.vue'
import Charlotte from '@/views/Charlotte.vue'

import MobileHome from '../views/m/Home.vue'
import MobilePerson from '../views/m/Person.vue'
import MobileAixia from '../views/m/Aixia.vue'
import MobileRuby from '../views/m/Ruby.vue'
import MobileAisha from '../views/m/Aisha.vue'
import MoblieZoey from '@/views/m/Zoey.vue'
import MobileElise from '@/views/m/Elise.vue'
import MobileGrace from '@/views/m/Grace.vue'
import MobileCharlotte from '@/views/m/Charlotte.vue'

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: () => import('../views/ForgetPwd.vue')
  },
  {
    path: '/termService',
    name: 'TermService',
    component: () => import('../views/user/TermOfService.vue')
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: () => import('../views/user/PrivacyPolicy.vue')
  },
  {
    path: '/fiona',
    name: 'Pserson',
    component: Person
  },
  {
    path: '/aixia',
    name: 'Aixia',
    component: Aixia
  },
  {
    path: '/ruby',
    name: 'Ruby',
    component: Ruby
  },
  {
    path: '/aisha',
    name: 'Aisha',
    component: Aisha
  },
  {
    path: '/zoey',
    name: 'Zoey',
    component: Zoey
  },
  {
    path: '/elise',
    name: 'Elise',
    component: Elise
  },
  {
    path: '/grace',
    name: 'Grace',
    component: Grace
  },
  {
    path: '/charlotte',
    name: 'Charlotte',
    component: Charlotte
  },
  {
    path: '/artist',
    name: 'Artist',
    component: Artist
  },
  {
    path: '/user/mylibrary',
    name: 'MyLibrary',
    component: () => import('../views/user/MyLibrary.vue')
  },
  {
    path: '/user/credits',
    name: 'Credits',
    component: () => import('../views/user/Credits.vue')
  },

  {
    path: '/m',
    redirect: '/m/index'
  },
  {
    path: '/m/index',
    name: 'MobileHome',
    component: MobileHome
  },
  {
    path: '/m/fiona',
    name: 'MobilePerson',
    component: MobilePerson
  },
  {
    path: '/m/ruby',
    name: 'MobileRuby',
    component: MobileRuby
  },
  {
    path: '/m/aisha',
    name: 'MobileAisha',
    component: MobileAisha
  },
  {
    path: '/m/aixia',
    name: 'MobileAixia',
    component: MobileAixia
  },
  {
    path: '/m/zoey',
    name: 'MoblieZoey',
    component: MoblieZoey
  },
  {
    path: '/m/elise',
    name: 'MobileElise',
    component: MobileElise
  },
  {
    path: '/m/grace',
    name: 'MobileGrace',
    component: MobileGrace
  },
  {
    path: '/m/charlotte',
    name: 'MobileCharlotte',
    component: MobileCharlotte
  },
]

const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  if (isMobile()) {
    if (~to.path.indexOf('/m/')) {
      next()
    } else {
      next(`/m${to.path}`)
    }
  } else {
    if (~to.path.indexOf('/m/')) {
      next(to.path.replace('/m/', '/'))
    } else {
      next()
    }
  }
})
export default router
