import Vue from 'vue'
import Vuex from 'vuex'
import request from "@/utils/request.js"
import { getAccountInfo } from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    config: {},
    token: localStorage.getItem('token') || '',
    userInfo: localStorage.getItem('__user') ? JSON.parse(localStorage.getItem('__user')) : '',
    accountInfo: localStorage.getItem('accountInfo') ? JSON.parse(localStorage.getItem('accountInfo')) : {},
  },
  getters: {
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    SET_ACCOUNT_INFO(state, accountInfo) {
      state.accountInfo = accountInfo
    },
    SET_CONFIG(state, config) {
      state.config = config
    }
  },
  actions: {
    getAccountInfo({ commit }) {
      return request(getAccountInfo, {}).then(data => {
        localStorage.setItem('accountInfo', JSON.stringify(data))
        commit('SET_ACCOUNT_INFO', data)
        return data
      })
    }
  },
  modules: {
  }
})
