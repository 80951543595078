import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import request from "@/utils/request.js"
import './utils/rem.js'
import 'element-ui/lib/theme-chalk/index.css';
import './styles/index.scss'
import './utils/date.js'
import getConfig from '@/utils/getConfig'
import { getMediaObsAddress } from '@/utils'

Vue.use(ElementUI)
Vue.config.productionTip = false

Vue.prototype.$http = request

Vue.filter('mediaPrefix', getMediaObsAddress)
getConfig().then((conf) => {
  Vue.prototype.$config = conf
  store.commit('SET_CONFIG', conf)
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
