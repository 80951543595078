<template>
  <div class="page">
    <MobileInnerHeader />
    <div class="part1">
      <div class="content">
        <img src="@/assets/images/m/home/part1_icon1.png" @click="handleShowVideo({ url: 'https://digital-public.obs.cn-east-3.myhuaweicloud.com/tici/zoey.mp4' })"><br>
        <img src="@/assets/images/m/zoey/part1_icon2.png"><br>
        <img src="@/assets/images/m/zoey/part1_icon5.png" @click="goLink('https://www.youtube.com/watch?v=43MtU7LBN2s')">
        <div class="icons">
          <img src="@/assets/images/new_home/youtube-icon.png" @click="goLink('https://www.youtube.com/@SoraiAiIdol')">
          <img src="@/assets/images/new_home/tiktok.png" @click="goLink('https://www.tiktok.com/@.aixiameta')">
          <img src="@/assets/images/new_home/douyin.png" @click="goLink('https://v.douyin.com/iYsA3VLw/')">
        </div>
      </div>
    </div>
    <div class="part2">
      <img src="@/assets/images/m/zoey/part2_icon1.png" alt="">
    </div>
    <div class="part3">
      <div class="panel">
        <div class="panel-title">SINGLES</div>
        <div class="panel-content">
          <div class="singles">
            <div v-for="item, index in audioList" :key="index" class="singles-item" @click="playAudio(item)" style="cursor: pointer;">
              <div class="single-left">
                <img :class="[{ rotate: item.isPlay }]" src="@/assets/images/person/part3_icon.png" width="90" alt="">
                <div class="single-info">
                  <p>{{ item.name }} </p>
                  <div>
                    <span>{{ item.languge }}</span><br>
                    <small class="duration">{{ item.duration }}</small>
                  </div>
                </div>
              </div>
              <img v-if="!item.isPlay" src="@/assets/images/person/audio_play.png" style="cursor: pointer;" width="46">
              <img v-else src="@/assets/images/person/audio_pause.png" style="cursor: pointer;" width="46" alt="" @click="pauseAudio(item)">
            </div>
          </div>
          <audio v-show="false" ref="audio" :src="currentAudio.url"></audio>
        </div>
      </div>
    </div>
    <Footer />
    <PreviewVideo :visible.sync="showVideo" :video-info="previewVideo" />
  </div>
</template>

<script>
import Footer from '@/components/m/Footer'
import PreviewVideo from '@/components/PreviewVideo.vue'
import MobileInnerHeader from '@/components/m/InnerHeader.vue'

export default {
  name: 'MobileAixia',
  components: {
    MobileInnerHeader,
    Footer,
    PreviewVideo
  },
  data() {
    return {
      audioList: [
        {
          url: 'https://digital-public.obs.cn-east-3.myhuaweicloud.com/100771/08e65b09574e1000.wav',
          link: 'https://www.youtube.com/watch?v=43MtU7LBN2s',
          name: '【Stand Strong】Original Song From AI Singer Zoey',
          languge: 'English',
          duration: '00:41',
          isPlay: false
        }
      ],
      currentAudio: {},
      showVideo: false,
      previewVideo: { url: '' },
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.handleAudio()
    })
  },
  methods: {
    // 监听音频播放
    handleAudio() {
      const audio = this.$refs.audio
      audio.addEventListener('playing', () => {
        if (this.currentAudio.isPlay) {
          this.currentAudio.isPlay = true
        }
      })
      audio.addEventListener('pause', () => {
        if (this.currentAudio.isPlay) {
          this.currentAudio.isPlay = false
        }
      })
      audio.addEventListener('ended', () => {
        if (this.currentAudio.isPlay) {
          this.currentAudio.isPlay = false
        }
      })
    },

    // 播放音频
    playAudio(item) {
      window.open(item.link, '_blank')
      return
      this.audioList.forEach(audio => {
        if (audio.isPlay) {
          audio.isPlay = false
        }
        if (audio === item) {
          audio.isPlay = true
        }
      })
      
      this.currentAudio = item
      this.$nextTick(() => {
        this.$refs.audio.play()
      })
    },

    // 暂停音频
    pauseAudio(item) {
      this.currentAudio.isPlay = false
      this.$nextTick(() => {
        this.$refs.audio.pause()
      })
    },

    goLink(url) {
      window.open(url, '_blank')
    },

    // 显示视频预览
    handleShowVideo(info) {
      this.previewVideo = info
      this.showVideo = true
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  &-title {
    font-size: 60px;
    text-align: center;
    font-weight: bold;
    padding: 92px 0;
  }
}

.part1 {
  font-size: 0;
  background: url(@/assets/images/m/zoey/part1_bg.jpg) no-repeat top center;
  background-size: cover;
  padding: 839px 0 70px;
  .content {
    text-align: right;
    padding: 0 6%;
    & > img:nth-child(1) {
      width: 98px;
    }
    & > img:nth-child(3) {
      margin-top: 61px;
      width: 274px;
    }
    & > img:nth-child(5) {
      margin-top: 59px;
      width: 240px;
    }
    .icons2 {
      display: flex; 
      gap: 24px; 
      margin: 58px 0; 
      justify-content: right;
      img {
        width: 151px;
      }
    }
    
    .icons {
      display: flex; 
      gap: 80px; 
      margin: 48px 0 0; 
      justify-content: right;
      img {
        height: 38px;
      }
    }
  }
}
.part2 {
  padding: 872px 50px 80px;
  background: url(@/assets/images/m/zoey/part2_bg.jpg) no-repeat top center;
  background-size: cover;
  img {
    width: 100%;
  }
}
.part3 {
  padding: 0 50px 80px;
  .singles {
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0,0,0,0.5);
      padding-bottom: 39px;
      margin-bottom: 39px;
      &:last-child {
        border-bottom: none;
      }
      & > img {
        width: 46px;
      }
    }
    .single-left {
      display: flex;
      align-items: center;
      gap: 34px;
      flex: 1;
      & > img {
        width: 100px;
      }
      .rotate {
        transition-property: transform;
        animation-name: rotate;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
      .single-info {
        color: #000;
        flex: 1;
        p {
          line-height: 36px;
          font-size: 24px;
          font-weight: bold;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 430px;
        }
        span {
          color: rgba(#000, .3);
          font-size: 22px;
          line-height: 40px;
        }
        small {
          color: rgba(#000, .3);
          font-size: 20px;
        }
      }
    }
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
</style>