<template>
  <div class="home">
    <header>
      <div class="logo"></div>
      <ul class="nav">
        <li @click="$router.push('/m/fiona')"><img src="@/assets/images/new_home/ava-icon.png" width="30" alt=""><span>fiona</span></li>
        <li @click="$router.push('/m/aixia')"><img src="@/assets/images/new_home/aixia-icon.png" width="30" alt=""><span>aixia</span></li>
      </ul>
    </header>
    <div class="part1">
      <div class="content">
        <img src="@/assets/images/m/home/part1_icon1.png" @click="handleShowVideo({ url: 'https://digital-public.obs.cn-east-3.myhuaweicloud.com/tici/7777.mp4' })"><br>
        <img src="@/assets/images/m/home/part1_icon2.png">
        <div class="icons2">
          <img src="@/assets/images/m/home/part1_icon3.png" @click="$router.push('/m/fiona')">
          <img src="@/assets/images/m/home/part1_icon4.png" @click="$router.push('/m/aixia')">
        </div>
        <img src="@/assets/images/m/home/part1_icon5.png" @click="goLink('https://www.youtube.com/@SoraiAiIdol')">
        <div class="icons">
          <img src="@/assets/images/new_home/youtube-icon.png" @click="goLink('https://www.youtube.com/@SoraiAiIdol')">
          <img src="@/assets/images/new_home/tiktok.png" @click="goLink('https://www.tiktok.com/@.aixiameta')">
          <img src="@/assets/images/new_home/douyin.png" @click="goLink('https://v.douyin.com/iYsA3VLw/')">
        </div>
      </div>
    </div>
    <div class="part2">
      <div class="panel">
        <div class="panel-title">VIDEO</div>
        <div class="panel-content">
          <swiper class="swiper" ref="mySwiper" :options="videoSwiperOption" @slideChange="onVideoSlideChange">
            <swiper-slide v-for="videos, index in videoList" :key="index">
              <div v-for="item in videos" :key="item.etag" class="video-item">
                <div class="ytb-thumb">
                  <img :src="item.snippet.thumbnails.high.url" @click="handleShowVideo({ url: item.videoId, source: 'youtube' })">
                </div>
                <p class="title">{{ item.snippet.title }}</p>
                <p class="time">{{ item.snippet.publishTime.substring(0, 10) }}</p>
              </div>
            </swiper-slide>
          </swiper>
          <div class="btns">
            <i :class="['prev-icon', { disabled: activeIndex === 0 }]"></i>
            <span>{{ activeIndex + 1 }} of {{ videoList.length }}</span>
            <i :class="['next-icon', { disabled: activeIndex === videoList.length - 1 }]"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="part3">
      <img src="@/assets/images/m/home/part3_icon1.png" alt="">
      <div class="fllowers">
        <p>{{ formateNum(700000) }}</p>
        <span>followers</span>
      </div>
      <div class="songs">
        <img src="@/assets/images/m/home/part3_icon2.png" alt=""><br>
        <span>songs</span>
      </div>
      <div class="singers">
        <p>{{ formateNum(10000) }}</p>
        <span>singers</span>
      </div>
    </div>
    <div class="part4">
      <div class="panel">
        <div class="panel-title">NEWS</div>
        <div class="panel-content">
          <div class="news">
            <div class="news-item">
              <img src="@/assets/images/new_home/part3-pic.png" alt="">
              <div class="new-content">
                <p>Sorai.ai, the Pure AI Idol Group, Wows Audiences at Beijing Film Festival</p>
                <span>Date of issue : 2024.04.16 </span>
                <div>Sorai.ai, a groundbreaking pure AI idol group, made a dazzling debut at the prestigious Beijing Film Festival. With their extraordinary vocalizations, crafted by AI algorithms, and personalized avatars cloned through advanced AI technology, Sorai.ai aims to revolutionize the music industry and redefine visual entertainment.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <PreviewVideo :visible.sync="showVideo" :video-info="previewVideo" />
  </div>
</template>

<script>
import { queryYoutubeVideo } from '@/api'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { throttle } from '@/utils'
import Footer from '@/components/m/Footer.vue'
import PreviewVideo from '@/components/PreviewVideo.vue'
import 'swiper/css/swiper.css'

export default {
  name: 'MobileHome',
  components: {
    Swiper, 
    SwiperSlide,
    Footer,
    PreviewVideo,
  },
  data() {
    return {
      activeIndex: 0,
      videoList: [],
      showVideo: false,
      previewVideo: { url: '' },
      videoSwiperOption: {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 30,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.next-icon',
          prevEl: '.prev-icon',
        }
      }
    }
  },
  computed: {
    formateNum() {
      return num => {
        const str = num.toString()
        const reg = /\B(?=(\d{3})+($|\.))/g
        return str.replace(reg, ',')
      }
    }
  },
  mounted() {
    this.getVideoList()
    this.$nextTick(() => {
      this.handleScroll()
    })
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 查询youtube视频列表
    getVideoList() {
      this.$http(queryYoutubeVideo, {
        channelId: 'UCWk2OKjmJmrOZEB2MECltOg',
        order: 'date',
        maxResults: 30
      }).then(data => {
        if (data && data.length > 0) {
          const ret = []
          data = data.map(item => ({ ...item, videoId: item.id.videoId })).sort((a, b) => new Date(b.snippet.publishTime).getTime() - new Date(a.snippet.publishTime).getTime())
          data.forEach((item, index) => {
            if (ret[Math.floor(index / 3)]) {
              ret[Math.floor(index / 3)].push(item)
            } else {
              ret[Math.floor(index / 3)] = [item]
            }
          })
          this.videoList = ret
        }
      })
    },

    // 滚动监听(节流)
    handleScroll: throttle(function() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.scrollTopNum = scrollTop
      let header = document.querySelector('header')
      if (scrollTop > 200) {
        header.classList.add('white')
      } else {
        header.classList.remove('white')
      }
    }, 150),
    
    goLink(url) {
      window.open(url, '_blank')
    },

    // 当视频slide改变
    onVideoSlideChange() {
      this.activeIndex = this.$refs.mySwiper.$swiper.activeIndex
    },

    // 显示视频预览
    handleShowVideo(info) {
      this.previewVideo = info
      this.showVideo = true
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  &-title {
    font-size: 60px;
    text-align: center;
    font-weight: bold;
    padding: 92px 0;
  }
}
header {
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 96%;
  z-index: 99;
  padding: 32px 3%;
  &.white {
    background-color: rgba(#fff, 1);
    .logo {
      background: url(@/assets/images/new_home/logo-blank.png) no-repeat 0 0;
      background-size: 100% auto;
    }
    .nav {
      span {
        color: #000;
      }
    }
  }
  .logo {
    background: url(@/assets/images/new_home/logo.png) no-repeat 0 0;
    background-size: 100% auto;
    width: 246px;
    height: 52px;
  }
  .nav {
    display: flex;
    align-items: center;
    gap: 42px;
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      cursor: pointer;
      span {
        color: #fff;
        font-size: 26px;
        padding: 5px 20px;
        line-height: 36px;
        vertical-align: middle;
        &.active {
          background: rgba(0,0,0,0.3);
          border-radius: 15px;
        }
      }
      img {
        vertical-align: middle;
        width: 40px;
      }
      &:last-child,  &:nth-child(1) {
        span {
          padding-left: 5px;
        }
      }
    }
  }
}

.part1 {
  font-size: 0;
  background: url(@/assets/images/m/home/part1_bg.png) no-repeat top center;
  background-size: cover;
  padding: 566px 0 70px;
  .content {
    text-align: right;
    padding: 0 6%;
    & > img:nth-child(1) {
      width: 98px;
    }
    & > img:nth-child(3) {
      width: 100%;
      margin-top: 62px;
    }
    .icons2 {
      display: flex; 
      gap: 24px; 
      margin: 58px 0; 
      justify-content: right;
      img {
        width: 151px;
      }
    }
    & > img:nth-child(5) {
      width: 240px;
    }
    .icons {
      display: flex; 
      gap: 80px; 
      margin: 48px 0 0; 
      justify-content: right;
      img {
        height: 38px;
      }
    }
  }
}

.part2 {
  .video-item {
    margin-bottom: 35px;
  }
  .swiper-slide {
    width: 100%;
    position: relative;
    .ytb-thumb {
      img {
        width: 100%;
        min-height: 300px;
      }
    }
    .title {
      padding: 27px 50px 0;
      font-size: 26px;
      color: #000;
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .time {
      font-size: 20px;
      padding: 10px 50px;
      color: rgba(0,0,0,0.5);
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:20px 0 80px;
    gap: 27px;
    .prev-icon {
      display: inline-block;
      width: 54px;
      height: 54px;
      background: url(@/assets/images/new_home/prev-icon.png) no-repeat 0 0;
      background-size: 100% 100%;
      cursor: pointer;
    }
    span {
      color: rgba(0,0,0,0.5);
      font-size: 24px;
    }
    .next-icon {
      display: inline-block;
      width: 54px;
      height: 54px;
      background: url(@/assets/images/new_home/next-icon.png) no-repeat 0 0;
      background-size: 100% 100%;
      cursor: pointer;
    }
    i.disabled {
      opacity: .4;
    }
  }
}
.part3 {
  background: url(@/assets/images/m/home/part3_bg.png) no-repeat top center;
  background-size: cover;
  padding: 176px 0 298px;
  text-align: center;
  & > :nth-child(1) {
    width: 271px;
  }
  :nth-child(2), :nth-child(3), :nth-child(4) {
    p {
      font-family: Montserrat, Montserrat;
      font-weight: bold;
      font-size: 80px;
      color: #fff;
      text-align: center;
    }
    span {
      font-size: 32px;
      color: rgba(255,255,255,0.6);
      line-height: 49px;
    }
  }
  .fllowers {
    margin-top: 165px;
    margin-bottom: 116px;
  }
  .songs {
    margin-bottom: 116px;
    img {
      width: 105px;
      margin-bottom: 10px;
    }
  }
}

.part4 {
  background-color: #F8F8F8;
  .news {
    padding-bottom: 80px;
    .news-item {
      padding: 0 52px;
      img {
        width: 100%;
      }
    }
    .new-content {
      p {
        padding-top: 12px;
        font-size: 32px;
        color: #000;
        line-height: 49px;
        font-weight: 600;
      }
      span {
        display: block;
        font-size: 20px;
        color: rgba(0,0,0,0.5);
        padding: 12px 0 24px;
      }
      & > div {
        border-top: 1px solid #DADADA;
        padding-top: 24px;
        font-size: 22px;
        color: rgba(0,0,0,0.8);
        line-height: 31px;
      }
    }
  }
}
</style>