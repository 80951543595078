<template>
  <div class="page">
    <InnerHeader :white="true" />
    <swiper :options="swiperOption" ref="myOutSwiper" @slideChange="onSlideChange">
      <swiper-slide>
        <div class="part1">
          <div class="content">
            <div class="panel">
              <div class="panel-title">ARTIST</div>
              <div class="panel-content artist">
                <div class="artist-item">
                  <p>Fiona</p>
                  <div class="artist-img">
                    <img src="@/assets/images/artist/author1.jpg" alt="">
                    <div class="mask">
                      <span @click="$router.push('/fiona')">Fiona</span>
                      <div class="link">
                        <img @click="goLink('https://www.youtube.com/@SoraiAiIdol')" src="@/assets/images/new_home/youtube-icon.png" alt="">
                        <img @click="goLink('https://www.tiktok.com/@.aixiameta')" src="@/assets/images/new_home/tiktok.png" alt="">
                        <img @click="goLink('https://v.douyin.com/iYsA3VLw/')" src="@/assets/images/new_home/douyin.png" alt="">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="artist-item">
                  <p>Aixia</p>
                  <div class="artist-img">
                    <img src="@/assets/images/artist/author2.jpg" alt="">
                    <div class="mask">
                      <span @click="$router.push('/aixia')">Aixia</span>
                      <div class="link">
                        <img @click="goLink('https://www.youtube.com/@SoraiAiIdol')" src="@/assets/images/new_home/youtube-icon.png" alt="">
                        <img @click="goLink('https://www.tiktok.com/@.aixiameta')" src="@/assets/images/new_home/tiktok.png" alt="">
                        <img @click="goLink('https://v.douyin.com/iYsA3VLw/')" src="@/assets/images/new_home/douyin.png" alt="">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="artist-item">
                  <p>Zoey</p>
                  <div class="artist-img">
                    <img src="@/assets/images/artist/author3.jpg" alt="">
                    <div class="mask">
                      <span @click="$router.push('/zoey')">Zoey</span>
                      <div class="link">
                        <img @click="goLink('https://www.youtube.com/@SoraiAiIdol')" src="@/assets/images/new_home/youtube-icon.png" alt="">
                        <img @click="goLink('https://www.tiktok.com/@.aixiameta')" src="@/assets/images/new_home/tiktok.png" alt="">
                        <img @click="goLink('https://v.douyin.com/iYsA3VLw/')" src="@/assets/images/new_home/douyin.png" alt="">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="artist-item">
                  <p>Ruby</p>
                  <div class="artist-img">
                    <img src="@/assets/images/artist/author4.jpg" alt="">
                    <div class="mask">
                      <span @click="$router.push('/ruby')">Ruby</span>
                      <div class="link">
                        <img @click="goLink('https://www.youtube.com/@SoraiAiIdol')" src="@/assets/images/new_home/youtube-icon.png" alt="">
                        <img @click="goLink('https://www.tiktok.com/@.aixiameta')" src="@/assets/images/new_home/tiktok.png" alt="">
                        <img @click="goLink('https://v.douyin.com/iYsA3VLw/')" src="@/assets/images/new_home/douyin.png" alt="">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="artist-item">
                  <p>Élise</p>
                  <div class="artist-img">
                    <div class="mask">
                      <span @click="$router.push('/elise')">Élise</span>
                      <div class="link">
                        <img @click="goLink('https://www.youtube.com/@SoraiAiIdol')" src="@/assets/images/new_home/youtube-icon.png" alt="">
                        <img @click="goLink('https://www.tiktok.com/@.aixiameta')" src="@/assets/images/new_home/tiktok.png" alt="">
                        <img @click="goLink('https://v.douyin.com/iYsA3VLw/')" src="@/assets/images/new_home/douyin.png" alt="">
                      </div>
                    </div>
                    <img src="@/assets/images/artist/author5.jpg" alt="">
                  </div>
                </div>
                <div class="artist-item">
                  <p>Grace</p>
                  <div class="artist-img">
                    <div class="mask">
                      <span @click="$router.push('/grace')">Grace</span>
                      <div class="link">
                        <img @click="goLink('https://www.youtube.com/@SoraiAiIdol')" src="@/assets/images/new_home/youtube-icon.png" alt="">
                        <img @click="goLink('https://www.tiktok.com/@.aixiameta')" src="@/assets/images/new_home/tiktok.png" alt="">
                        <img @click="goLink('https://v.douyin.com/iYsA3VLw/')" src="@/assets/images/new_home/douyin.png" alt="">
                      </div>
                    </div>
                    <img src="@/assets/images/artist/author6.jpg" alt="">
                  </div>
                </div>
                <div class="artist-item">
                  <p>Aisha</p>
                  <div class="artist-img">
                    <div class="mask">
                      <span @click="$router.push('/aisha')">Aisha</span>
                      <div class="link">
                        <img @click="goLink('https://www.youtube.com/@SoraiAiIdol')" src="@/assets/images/new_home/youtube-icon.png" alt="">
                        <img @click="goLink('https://www.tiktok.com/@.aixiameta')" src="@/assets/images/new_home/tiktok.png" alt="">
                        <img @click="goLink('https://v.douyin.com/iYsA3VLw/')" src="@/assets/images/new_home/douyin.png" alt="">
                      </div>
                    </div>
                    <img src="@/assets/images/artist/author7.jpg" alt="">
                  </div>
                </div>
              </div>
            </div>
            <p class="more">More artists are about to debut soon...</p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <Footer />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import InnerHeader from '@/components/InnerHeader'
import Footer from '@/components/Footer'
export default {
  name: 'Artist',
  components: {
    Swiper,
    SwiperSlide,
    InnerHeader,
    Footer
  },
  data() {
    return {
      slideIndex: 0,
      swiperOption: {
        direction: 'vertical', // 竖向滚动
        autoHeight: true, // 自适应高度
        slidesPerView: 'auto', // 记得这里写 auto 不要默认写1哦
        mousewheel: true, // 支持鼠标滚动
        height: window.innerHeight
      },
    }
  },
  methods: {
    // 当slide改变
    onSlideChange() {
      this.slideIndex = this.$refs.myOutSwiper.$swiper.activeIndex
    },
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .swiper-slide {
    height: 100% !important;
    overflow: hidden;
  }
}
.content {
  width: 1100px;
  margin: 0 auto;
}
.panel {
  &-title {
    font-size: 60px;
    color: #000;
    font-weight: bold;
    text-align: center;
    padding-bottom: 20px;
  }
}
.part1 {
  font-size: 0;
  position: relative;
  overflow: hidden;
  height: 100vh;
  .panel {
    padding: 106px 0 0;
  }
  .artist {
    display: flex;
    flex-wrap: wrap;
    gap: 27px;
    .artist-item {
      width: calc(25% - 27px);
      p {
        font-size: 18px;
        color: #000;
        font-weight: bold;
        padding-bottom: 10px;
      }
      .artist-img {
        position: relative;
        & > img {
          width: 100%;
        }
        &:hover {
          .mask {
            opacity: 1;
            z-index: 99;
            transition: .3s;
          }
        }
        .mask {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(#000, .5);
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          z-index: -1;
          span {
            font-size: 30px;
            color: #fff;
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;
          }
          .link {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;
            position: absolute;
            bottom: 15px;
            img {
              height: 26px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .content > p {
    font-size: 16px;
    color: #BFBFBF;
    text-align: center;
    padding-top: 15px;
    font-style: italic;
  }
}
</style>