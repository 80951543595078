export const queryYoutubeVideo = '/sorai-api-anylang-anylang/youtube/search post' // 查询youtube视频
export const querySongList = '/video-api/song/list get' // 查询歌曲
export const querySingerCategory = '/video-api/singer/category get' // 查询歌手分类
export const querySingerList = '/video-api/singer/list get' // 查询歌手
export const queryMyTemplateList = '/video-api/suno/user/template/list get' // 查询我自己创建的歌手
export const saveOrUpdateMyTemplate = '/video-api/suno/user/template/saveOrUpdate post' // 添加或修改我的歌手形象
export const delMyTemplate = '/video-api/suno/user/template/del get' // 删除我的歌手形象
export const saveVideo = '/video-api/suno/saveVideo post' // 添加作品
export const updateVideo = '/video-api/suno/updateVideo post' // 修改作品
export const synthesisVideo = '/video-api/suno/synthesis post' // 作品合成
export const videoList = '/video-api/suno/listVideo get' // 作品列表
export const queryVideoById = '/video-api/suno/queryVideoById get' // 根据ID查询视频作品
export const delVideo = '/video-api/suno/delVideo get' // 视频删除

export const signUp = '/video-api/login/signUp post' // 邮箱注册
export const sendEmailCode = '/video-api/login/emailCode post' // 发送邮箱验证码
export const signIn = '/video-api/login/signIn post' // 使用邮箱登录
export const signOut = '/video-api/login/signOut post' // 主动退出
export const passwordForget = '/video-api/login/passwordForget post' // 邮箱忘记密码
export const getAccountInfo = '/video-api/suno/user/account get' // 获取账户信息

export const packageList = '/video-api/suno/setMeal/list get' // 套餐列表
export const createOrder = '/video-api/suno/order/create post' // 创建订单
export const queryOrderInfo = '/video-api/suno/order/query get' // 查询订单
export const orderCallback = '/video-api/suno/order/callback post' // 订单回调