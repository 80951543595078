<template>
  <header :class="[{white: white}]">
    <div class="logo" @click="$router.push('/index')"></div>
    <ul class="nav">
      <li><span @click="$router.push('/')">Home</span></li>
      <li><span @click="$router.push('/user/credits')">Pricing</span></li>
    </ul>
    <div class="link">
      <RightHeader :class="[{white: !white}]" />
    </div>
  </header>
</template>

<script>
import RightHeader from '@/components/RightHeader'

export default {
  name: 'InnerHeader',
  components: {
    RightHeader
  },
  props: {
    white: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
    goLink(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 96%;
  z-index: 99;
  padding: 22px 3%;
  &.white {
    background-color: rgba(#fff, .9);
    .logo {
      background: url(@/assets/images/new_home/logo-blank.png) no-repeat 0 0;
      background-size: 100% auto;
    }
    .nav {
      span {
        color: #000;
      }
    }
    .link {
      .ytb-icon {
        background: url(@/assets/images/new_home/youtube-icon2.png) no-repeat;
        background-size: 100% 100%;
      }
      .tiktok-icon {
        background: url(@/assets/images/new_home/tiktok2.png) no-repeat;
        background-size: 100% 100%;
      }
      .douyin-icon {
        background: url(@/assets/images/new_home/douyin2.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .logo {
    background: url(@/assets/images/new_home/logo.png) no-repeat 0 0;
    background-size: 100% auto;
    width: 222px;
    height: 42px;
    cursor: pointer;
  }
  .nav {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      cursor: pointer;
      span {
        color: #fff;
        font-size: 20px;
        padding: 5px 20px;
        line-height: 36px;
        vertical-align: middle;
        &.active {
          background: rgba(0,0,0,0.3);
          border-radius: 15px;
        }
      }
      img {
        vertical-align: middle;
      }
      &:last-child {
        span {
          padding-left: 5px;
        }
      }
    }
  }
  .link {
    .white {
      :deep {
        span {
          color: #fff !important;
        }
      }
    }
  }
}
</style>