import { Message } from 'element-ui'

let messageInstance = null
const resetMessage = (options) => {
  if (messageInstance) {
    messageInstance.close()
  }
  messageInstance = Message(options)
}

['error', 'success', 'info', 'warning'].forEach(type => {
  resetMessage[type] = (options) => {
    if (typeof options === 'string') {
      options = { message: options }
    }
    options.type = type
    // if (type === 'error') {
    //   options.message = h('div', null, [
    //     h('div', null, '2222'),
    //     h('div', null, options.message || '333')
    //   ])
    // }
    return resetMessage(options)
  }
})

export default resetMessage
